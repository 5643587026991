.header {
  &:before {
    display: none;
  }

  .smallCircle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #BB0000;

    &.connected {
      background: #00BB00;
    }
  }
}

